.heading{
    font-weight: 700;
    font-size: 20px;
}

.heroSection{
    width: 40%;
}
.environmentContainer{
    display: flex;
    justify-content: space-between;
}
.environmentName{
    font-weight: 700;
    font-size: 15px;
}
