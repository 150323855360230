// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$white: #ffffff;
$gray-100: #f7fafc;

$gray-f9: #f8f9fb;
$text-color: #1e2c4f;
$link-color: #02edfe;
// Color Shades (Theme Based - Xclusive)
$theme-primary: #345eff;
$theme-secondary: #0f1938;
$theme-success: #16a0a0;
$theme-dark: #25282b;
$theme-info: #9c6dff;
$theme-warning: #ffcf01;
$theme-green: #36942f;
$theme-purple: #532eb3;
$theme-gray: #e2e8f0;
$theme-error: #eb106c;

// border-colors

$border-primary: #e5e9f3;
$border-left: #0ea8ff;
