@import "../../styles/variables/colors";

.page-header {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;

  .header-title {
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 136.02%;
    color: rgba($text-color, 92%);
  }
}
