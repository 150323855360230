// variables
@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.horizontal-navigation {
  background: $white !important;
  border-bottom: 1px solid #e5e9f3;

  .ant-menu-horizontal {
    display: flex;
    justify-content: flex-end;
    border: none;
  }

  .icon-style img {
    object-fit: contain;
  }
}

// theme sidebar
.theme-sidebar {
  background: $white !important;
  min-width: 242px !important;
  border-right: 1px solid #e5e9f3;
  height: 100vh;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .sidebar-first {
    flex-grow: 2;
  }

  .logo-sec {
    padding: 57px;
  }

  .download-btn {
    margin-bottom: 50px;
    padding: 0 21px;
  }

  .ant-btn-default {
    .theme-style {
      background-color: $theme-primary;
    }
  }

  .ant-menu-inline {
  }

  .icon-style img {
    object-fit: contain;
  }

  .ant-menu-inline > .ant-menu-item {
    height: 48px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: linear-gradient(
      90deg,
      #ebefff 0.83%,
      rgba(235, 239, 255, 0) 100%
    );
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    right: auto;
    left: 0;
    top: 25%;
    top: 25%;
    border-radius: 0px 8px 8px 0px;
    height: 60%;
    border-width: 4px;
  }

  .icon-style {
    object-fit: contain;
  }

  .user-btn {
    position: sticky;
    bottom: 0;

    .link {
      display: flex;
      align-items: center;
    }

    .name {
      font-weight: 600;
      margin-left: 10px;
    }

    .request {
      font-size: 18px;
      text-align: center;
      margin-bottom: 50px;
      opacity: 0.7;
      font-weight: 500;
    }

    .ant-btn {
      bottom: 20px;
      height: 60px;
      width: 90%;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0;
      border: none;
      border-top: 1px solid #e5e9f3;
      color: rgba(30, 44, 79, 0.8);
      font-weight: 500;
      font-size: 16px;
    }
  }
}

// environment-popup
.ant-popover-inner-content {
  padding: 0 !important;
}
.environment-popup {
  min-width: 300px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 30px;
    padding: 20px 20px 0;
  }

  .btn-plus {
    height: 40px;
  }

  .environment-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    a {
      padding: 15px 20px;
      border-top: 1px solid $border-primary;
      color: $theme-dark;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: rgba($theme-primary, 0.1);
      }
    }
  }
}
