@import "../../styles/variables/colors";

.target-audience-page {
  .page-header-title {
    display: flex;
    align-items: center;
    gap: 13.3px;

    .left-icon {
      width: 21px;
      height: 21px;
    }

    .header-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 136.02%;
      color: rgba($text-color, 92%);
    }
  }
}

// target-audience

.target-audience {
  //   margin-left: 40px;
  padding-top: 47px;

  .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: 20px;
    line-height: 136.02%;
    color: rgba($text-color, 92%);
  }
  .audience-tabs {
    .ant-tabs-nav::before {
      border: none;
    }
  }

  .ant-tabs-tab {
    justify-content: center;
    width: 160px;
    height: 52px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab-active {
    background: $theme-primary;
    border-radius: 119px;

    .ant-tabs-tab-btn {
      color: $white !important;
      font-weight: 400;
      font-size: 20px;
      line-height: 136.02%;
    }
  }
  .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: 20px;
    line-height: 136.02%;
    color: rgba($text-color, 92%);
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-nav-wrap {
    background: $gray-f9;
    height: 60px;
    border-radius: 119px;
    max-width: 330px;
    max-height: 52px;
  }
  .ant-tabs-centered
    > .ant-tabs-nav
    .ant-tabs-nav-wrap:not([className*="ant-tabs-nav-wrap-ping"]) {
    justify-content: flex-start;
  }
}

// user-container
.user-container {
  .ant-card {
    border: 1px solid $border-primary;
    border-radius: 8px;

    .card-top {
      display: flex;
      justify-content: space-between;
    }
    .ant-card-body {
    }

    .upload-btn {
      .ant-btn {
        height: 36px;
        // font-weight: 600;
        font-size: 12px;
        line-height: 14px;

        svg {
          width: 18.3px;
          height: 15.8px;
        }
      }
    }

    .card-tag-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 136.52%;
      color: rgba($text-color, 92%);
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba($text-color, 80%);
    }
  }
  .select-data {
    margin-top: 16px;
  }

  .save-target-btn {
    .ant-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

// upload-modal

.data-modal {
  width: 640px !important;
  .ant-modal-content {
    .ant-modal-header {
      display: none;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close-x {
      display: none;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}

.modal-data-upload {
  .modal-header {
    padding: 25.5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid $border-primary;

    .header-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 136.52%;
      margin: 0;
      color: $black;
    }
    a {
      color: $theme-primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.005em;
    }
  }
  .modal-content {
    padding: 23px 40px;

    .content-header {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 5px;
      border-radius: 4px;
      padding: 0 11px;
      height: 64px;
      border-left: 7px solid $border-left;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: rgba($text-color, 92%);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.95),
          rgba(255, 255, 255, 0.95)
        ),
        #0ea8ff;
      .header-para {
        margin: 0;
      }
      a {
        color: #0ea8ff;
      }
    }

    .ant-upload-drag-icon {
      img {
        width: 56px;
        height: 48.3px;
      }
    }

    .ant-upload-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.005em;
      color: rgba($text-color, 80%);
      margin-bottom: 8px;
    }

    .ant-upload-hint {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: rgba($text-color, 64%);
    }
  }

  .upload.middle {
    width: 100%;

    .ant-upload.ant-upload-drag {
      border: 1px dashed #d9d9d9;
      padding: 46px 0 40px 0;
    }
  }

  .url-upload {
    width: 100%;
    .upload-input {
      margin-top: 6px;
      display: flex;
      width: 100%;
      border: 1px solid $border-primary;
      border-radius: 8px;
      align-items: center;
      padding: 0 11px;
      .ant-input {
        width: 100%;
        border: none;
      }

      .ant-btn {
        width: auto;
        background: rgba($theme-primary, 35%);
        border: none;
      }
    }
  }
}
