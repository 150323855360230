@import url("https://use.typekit.net/zfc4puj.css");

// variables
@import "./variables/colors";
@import "./variables/mixins";

// theme styles files
@import "./xtras";
// @import './fonts';

// custom css
* {
  font-family: "sofia-pro", sans-serif !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// theme-layout-main
.theme-layout-main {
  min-height: 100vh;
  width: 100%;
  background: $white;

  .site-content {
    padding: 25px;
    height: calc(100vh - 234px);
    overflow-x: scroll;
  }
}

#root {
  // height: 100%;
}

.app-container {
  position: relative;
  // height: 100%;
  // overflow-x: hidden;

  overflow-x: hidden;
}

.link-style {
  color: $theme-primary;
  text-transform: capitalize;

  &:hover {
    color: rgba($theme-primary, 0.8);
  }
}

.dropdown-btn-link {
  padding: 0;
  font-size: 20px;
  filter: grayscale(100);
}

// Typography Style
.title1,
.title2,
.title3,
.title4,
.title5,
.title6 {
  margin: 0;
  color: rgba($theme-secondary, 0.92);
}

.title1 {
  font-size: 2rem;
  line-height: 39px;
}

.title2 {
  font-size: 1.75rem;
  line-height: 39px;
  font-weight: 500;
}

.title3 {
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 500;

  // On mobile
  @include mobile {
    font-size: 1.3rem;
  }
}

.title4 {
  font-size: 1.3rem;
  line-height: 39px;
  font-weight: 500;
}

.title5 {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 500;
}

.title6 {
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 500;
}

.title-semibold {
  font-weight: 600;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba($theme-secondary, 0.8);
}

// ButtonS
.ant-btn-default {
  background: #ebefff;
  border-radius: 4px;
  width: 100%;
  height: 58px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #ebefff;
  }
}

.btn-outline {
  background: none;
  border: 1px solid rgba($theme-primary, 0.3);
  color: $theme-primary;
  width: auto;
  height: 44px;
  border-radius: 4px;
  background: rgba($theme-primary, 0.01);

  &.btn-sm {
    height: 36px;
  }
}

.alert-info {
  border: none;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0ea8ff;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  position: relative;
  border-radius: 4px;
  font-size: 20px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    background-color: #0ea8ff;
    content: "";
  }

  span {
    color: #0ea8ff;
    margin-left: 5px;
  }
}

// ant-input
.ant-input {
  height: 56px;
  border: 1px solid #e5e9f3;
  border-radius: 12px;
}

.ant-btn {
  line-height: 0;
  border-radius: 4px;
}

// ant-btn-link
.ant-btn-link {
  font-weight: 500;
  color: $theme-primary;
}

.ant-input-affix-wrapper {
  padding: 0 15px;
  border-radius: 12px;
}

.ant-form-item-label label {
  font-size: 16px;
  color: rgba(30, 44, 79, 0.64);
}

// btn-none
.btn-none {
  border: none;
}

.ant-modal-mask {
  background-color: rgba(#1e2c4f, 80%) !important;
}

.search-section {
  display: flex;
  align-items: center;
  gap: 0 10px;

  .ant-input-search {
    position: relative;
    border: 1px solid #e5e9f3;
    border-radius: 8px;
    overflow: hidden;

    .ant-input-affix-wrapper {
      border: none;
      padding-left: 38px;
    }
  }

  .ant-input-group-addon {
    position: absolute;
    top: 12px;
    left: 10px !important;
    z-index: 1;

    .ant-btn-default {
      height: auto;
      border: none;
      background: none;
    }
  }

  .ant-input {
    height: 40px;
  }

  .filter-btn {
    background: none;
    padding: 0;
    width: auto;
    color: $theme-primary;
    font-size: 20px;
    margin-left: 10px;
  }
}

.table-card-design {
  margin-top: 25px;

  .head-section {
    background: $gray-f9;
    border-bottom: 1px solid $border-primary;
    padding: 10px 20px;
  }

  .ant-card-body {
    padding: 0;
  }
}

// tag-default
.tag-sec {
  display: flex;
  flex-wrap: wrap;

  .tag-default {
    border: none;
    background: #f8f9fb;
    border: 1px solid #e5e9f3;
    border-radius: 8px;
    min-width: 72px;
    height: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($theme-secondary, $alpha: 0.8);
  }
}

// status-dropdown
.status-dropdown {
  padding: 0;
  min-width: 200px !important;
  border: 1px solid rgba($theme-primary, 0.2);
  border-radius: 4px;
  overflow: hidden;

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 0;
  }

  .item-custom {
    padding: 8px 10px;
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
  }
  .menu-bg-style {
    background: rgba($theme-primary, 0.1);
    color: $theme-primary;
  }
}

// feature-status-modal
.feature-modal {
  .ant-modal-content {
    border-radius: 16px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  }

  .button-section {
    display: flex;
    align-items: center;
    gap: 0 20px;

    .ant-btn {
      height: 44px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .title4 {
    margin-bottom: 20px;
    font-weight: 600;
  }

  // enablement-sec
  .enablement-sec {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 0 10px;

    input {
      width: 70px;
      border: 1px solid #e5e9f3;
      border-radius: 4px;

      &:focus,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .button-sec {
    .ant-btn {
      height: 44px;
      width: 60%;
    }
  }

  .theme-primary {
    color: $theme-primary;
  }
}

.change-password-modal {
  .ant-modal-content {
    overflow: hidden;
  }

  .button-section {
    background: $gray-f9;
    padding: 20px;
    justify-content: flex-end;

    .ant-btn {
      max-width: 100px;
    }
  }

  .head-style {
    padding: 20px 20px 0;
  }

  .space-style {
    padding: 0px 20px;
  }

  .ant-modal-body {
    padding: 0;
  }
}

.delete-account-modal {
  .ant-modal-content {
    overflow: hidden;
  }

  .button-section {
    background: $gray-f9;
    padding: 20px;
    justify-content: flex-end;
    margin-top: 40px;

    .ant-btn {
      max-width: 120px;
    }
  }

  .space-style {
    padding: 0px 20px;
    max-width: 90%;
  }

  .ant-modal-body {
    padding: 30px 0 0;
  }

  .title4 {
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
    line-height: 1.5;

    &.text-lg {
      font-size: 16px;
    }
  }

  .delete-btn {
    background: $theme-error;
    color: $white;

    &:hover {
      opacity: 0.8;
    }
  }
}

// btn-style-none
.btn-style-none {
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

// back-btn
.back-btn {
  color: $theme-dark;
  display: flex;
  align-items: center;
  gap: 0 6px;
  font-size: 16px;

  &:hover {
    color: $theme-dark;
    opacity: 0.6;
  }

  img {
    width: 15px;
  }
}
.ant-typography {
  &.tip-text{
    font-size: 0.75rem;
  }
}




.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: $text-color;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: $text-color;
}
.ant-typography-copy-success,
.ant-typography-copy-success:focus,
.ant-typography-copy-success:hover {
  color: $theme-primary;
}
